.container {
    display: block;

    & img {
        max-height: 100px;
        max-width: 120px;
    }
}



